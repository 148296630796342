body {
  font-family: -apple-system, system-ui, sans-serif;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 45em;
  padding: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-top: 2em;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.5em;
}

h5 {
  font-size: 1.25em;
}

h6 {
  font-size: 1em;
}

a {
  color: #26f;
}

code {
  font-family: Menlo, monospace;
  font-size: 80%;
  background-color: #f4f5f6;
  padding: 0.2em 0.4em;
}

pre > code {
  background-color: #f4f5f6;
  border-left: 0.3em solid #26f;
  display: block;
  padding: 1em;
  white-space: pre-wrap;
}

img {
  max-width: 45em;
}

img.header{
  max-width: 45em;
}


@media only screen and (max-width: 479px){  
  img {
    width: 18em;
  }
  img.header{
    max-width: 18em;
  }
}

@media only screen and  (min-width: 480px) and (max-width: 719px){  
  img {
    width: 24em;
  }
  img.header{
    max-width: 24em;
  }
}



@media (prefers-color-scheme: dark) {
  html {
    background-color: #181a1b !important;
  }
  html, body, input, textarea, select, button {
      background-color: #181a1b;
  }
  html, body, input, textarea, select, button {
      border-color: #736b5e;
      color: #e8e6e3;
  }
  a {
      color: #3391ff;
  }
  table {
      border-color: #545b5e;
  }
  ::placeholder {
      color: #b2aba1;
  }
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
      background-color: #555b00 !important;
      color: #e8e6e3 !important;
  }
  ::-webkit-scrollbar {
      background-color: #202324;
      color: #aba499;
  }
  ::-webkit-scrollbar-thumb {
      background-color: #454a4d;
  }
  ::-webkit-scrollbar-thumb:hover {
      background-color: #575e62;
  }
  ::-webkit-scrollbar-thumb:active {
      background-color: #484e51;
  }
  ::-webkit-scrollbar-corner {
      background-color: #181a1b;
  }
  * {
      scrollbar-color: #202324 #454a4d;
  }
  ::selection {
      background-color: #004daa !important;
      color: #e8e6e3 !important;
  }
  ::-moz-selection {
      background-color: #004daa !important;
      color: #e8e6e3 !important;
  }
  code{
    background-color: #272822;
  }

  div.littlefoot-footnote__content {
    background-color:#333;
  }

}